import React, { useState, useContext, Fragment } from 'react';
import { globalContext } from '../../config/globalConfig';
import PhotoUpload from '../../components/photoUpload/index.jsx';
import PhotoGallery from '../../components/photoGallery/index.jsx';
import './memories.scss';
import { Input, InputLabel } from '@mui/material';

export default function Memories() {
  const [selectedFile, setSelectedFile] = useState(null);

  const { global } = useContext(globalContext);
  const {
    memories: {
      settings: {
        memoriesData: {
          memoriesTitle,
          socialText,
          uploadPhotosText,
          uploadText,
        },
        social,
        photoUpload,
      },
    },
  } = global.pages;

  return (
    <div className='memories-container'>
      <h1 className='memories-h1'>{memoriesTitle}</h1>
			<PhotoGallery />
      {social ? <p className='social-text'>{socialText}</p> : null}
      {photoUpload ? (
        <Fragment>
          <h2 className='memories-h2'>{uploadPhotosText}</h2>
          <p className='upload-text'>{uploadText}</p>
          {selectedFile ? (
            <>
              <form className='memories-form'>
                <InputLabel
                  className='memories-name-InputLabel'
                  htmlFor='InputLabel-name'
                >
                  <Input
                    type='text'
                    name='name'
                    id='InputLabel-name'
                    placeholder='Name'
                    className='memories-name-input'
                  />
                </InputLabel>
                <InputLabel className='memories-email-InputLabel'>
                  <Input
                    type='text'
                    name='email'
                    placeholder='Email'
                    className='memories-email-input'
                  />
                </InputLabel>
                <InputLabel className='memories-message-InputLabel'>
                  <Input
                    name='message'
                    placeholder='Message'
                    className='memories-message-input'
                    multiline
                    rows={4}
                  />
                </InputLabel>
              </form>
            </>
          ) : null}
          <PhotoUpload
            onFileSelected={setSelectedFile}
            selectedFile={selectedFile}
          />
        </Fragment>
      ) : null}
    </div>
  );
}

import React, {useContext} from 'react';
import { useAuth } from '../../utils/AuthContext.jsx';
import { globalContext } from '../../config/globalConfig';
import imageMain from '../../assets/home/imageMain.webp'
import './home.scss';

const Home = () => {
	const { global } = useContext(globalContext);
  const {
    home: {
      settings: {
        homeData: {
					celebrateMessage
        }
      }
    }
  } = global.pages;
	const { guestData } = useAuth();

	function welcomeMessage(guestsData) {
		const guests = guestsData.guests;
	
		if (!guests || guests.length === 0) {
			return 'Welcome!';
		}
	
		// Check if all guests have the same last name
		const lastName = guests[0].last_name;
		let allSameLastName = true;
	
		for (let i = 1; i < guests.length; i++) {
			if (guests[i].last_name !== lastName) {
				allSameLastName = false;
				break;
			}
		}
	
		if (allSameLastName) {
			// All guests have the same last name
			return `Welcome ${lastName} Family!`;
		} else {
			// Guests have different last names
			if (guests.length === 2) {
				// Special message for two guests
				return `Welcome ${guests[0].first_name} and ${guests[1].first_name}!`;
			} else {
				// Construct the message for more than two guests
				const names = guests.map(guest => guest.first_name);
				const lastGuest = names.pop();
				return `Welcome ${names.join(', ')} and ${lastGuest}!`;
			}
		}
	}
	

  return (
    <div className='main-container'>
      <div className='boat-img-container'>
        <img src={imageMain} alt='Description' className='boat-img' />
      </div>
      <h1 className='welcome-msg'>{welcomeMessage(guestData)}</h1>
      <p className='celebrate-msg'>
				{celebrateMessage}
      </p>
    </div>
  );
};

export default Home;
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useAuth } from '../../utils/AuthContext.jsx';
import { useRsvp } from '../../utils/rsvpContext.jsx';
import { globalContext } from '../../config/globalConfig';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import './rsvp.scss';

export default function Rsvp() {
  const [rsvpAll, setRsvpAll] = useState(null);
  const [attendingGuests, setAttendingGuests] = useState({});
  const [rsvpSuccess, setRsvpSuccess] = useState(false);
  const [rsvpTrue, setRsvpTrue] = useState(false);
  const [activityRsvp, setActivityRsvp] = useState(false);
  const [activityPersonCount, setActivityPersonCount] = useState(0);
  const [activityExpanded, setActivityExpanded] = useState(false); // New state

  const { global } = useContext(globalContext);
  const {
    rsvp: {
      settings: {
        rsvpData: {
          thankYouText,
          attendingText,
          selectAttendingText,
          rsvpBtnSome,
          changeRsvpText,
          activityText,
          partialAttendingText,
          activityLocation,
          activityExtraText,
					postRsvpText,
        },
      },
    },
  } = global.pages;
  const { celebrating } = useContext(globalContext);
  const { guestData } = useAuth();
  const { rsvpData } = useRsvp(); // Destructure rsvpData from useRsvp

  useEffect(() => {
    if (guestData?.guests && Array.isArray(rsvpData)) {
      const authGuests = guestData.guests;
      const rsvpGuests = rsvpData;

      const found = authGuests.some(authGuest =>
        rsvpGuests.some(rsvpGuest =>
          // authGuest.first_name === rsvpGuest.first_name &&
          // authGuest.last_name === rsvpGuest.last_name &&
					authGuest.guest_id === rsvpGuest.guest_id
        )
      );

      if (found) {
        setRsvpTrue(true);
      }
    }
  }, [guestData, rsvpData]);

  useEffect(() => {
    const initialGuests = {};
    guestData?.guests.forEach((guest) => {
      initialGuests[guest.guest_id] = true;
    });
    setAttendingGuests(initialGuests);
  }, [guestData?.guests]);

  const handleSetAttendingGuests = (guest_id) => (event) => {
    setAttendingGuests((prev) => ({
      ...prev,
      [guest_id]: event.target.checked,
    }));
  };

  const handleActivityChange = (event) => {
    setActivityRsvp(event.target.checked);
    if (!event.target.checked) {
      setActivityPersonCount(0); // Reset the count if the checkbox is unchecked
    }
  };

  const handleActivityCountChange = (event) => {
    const count = parseInt(event.target.value, 10);
    if (count <= guestData.guests.length) {
      setActivityPersonCount(count);
    } else {
      setActivityPersonCount(guestData.guests.length); // Set to max guests if input exceeds
    }
  };

  const handleRsvpAll = async () => {
    const updatedGuests = {};
    guestData.guests.forEach((guest) => {
      updatedGuests[guest.guest_id] = true; // Set each guest to attending
    });
    setAttendingGuests(updatedGuests);
    await submitRsvp(updatedGuests);
  };

  const handleChooseAttendees = () => {
    setRsvpAll(false);
  };

  const handleReset = () => {
    setRsvpAll(null);
    const initialGuests = {};
    guestData.guests.forEach((guest) => {
      initialGuests[guest.guest_id] = true;
    });
    setAttendingGuests(initialGuests);
    setActivityRsvp(false);
    setActivityPersonCount(0);
  };

  const submitRsvp = async (guests) => {
    const payloadArr = Object.keys(guests).map((key) => ({
      guestId: parseInt(key, 10),
      rsvp: guests[key],
      activityRsvp,
      activityPersonCount,
    }));

    try {
      const response = await fetch('/api/data/setRsvp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payloadArr),
      });

      await response.json();
    } catch (error) {
      console.error('Error updating RSVP:', error);
    } finally {
      setRsvpSuccess(true);
    }
  };

  const handleSubmitRsvp = async (event) => {
    event.preventDefault();
    await submitRsvp(attendingGuests);
  };

  return (
    <Fragment>
      {rsvpSuccess || rsvpTrue ? (
        <div className='success-wrapper'>
          <p className='thank-you-text'>{thankYouText}</p>
          <p className='thank-you-text'>{changeRsvpText}</p>
					<p className='thank-you-text'>{postRsvpText}</p>
        </div>
      ) : (
        <div className='rsvp-form-container'>
          <h2 className='rsvp-welcome-text'>{`Hello ${guestData.guests[0].last_name} party! Welcome to the RSVP portal. Please RSVP by ${celebrating.date}.`}</h2>

          <h4 className='rsvp-welcome-text-2'>Please fill out the Pub crawl section if you would like to participate before submitting your RSVP.</h4>

          <Accordion onChange={(event, isExpanded) => setActivityExpanded(isExpanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Haunted Pub Crawl RSVP</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className='form-sub-container'>
                <p className='attending-text'>{activityText}</p>
                <p className='activity-location-text'>{activityLocation}</p>
                <p className='activity-extra-info-text'>{activityExtraText}</p>
                <a href='https://hauntedhistorytours.com/' target='_blank' rel="noreferrer"><p>Click here for more information</p></a>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activityRsvp}
                      onChange={handleActivityChange}
                      name='activityRsvp'
                      className='activity-checkbox'
                    />
                  }
                  label="I / we want to participate in the haunted pub crawl (21+)"
                  className='activity-form-control-label'
                />
                {activityRsvp && (
                  <div className='activity-section'>
                    <TextField
                      type='number'
                      label='Number of Participants'
                      value={activityPersonCount}
                      onChange={handleActivityCountChange}
                      InputProps={{ inputProps: { min: 0, max: guestData.guests.length } }}
                      className='activity-count-input'
                    />
                    <p className='activity-continue'>Please continue to the River Cruise section to complete your RSVP.</p>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion disabled={!activityExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
							{activityExpanded ? <h3>River Cruise RSVP</h3> : <h3>{`River Cruise RSVP (review section above to enable)`}</h3>}
            </AccordionSummary>
            <AccordionDetails>
              <div className='form-sub-container'>
                <p className='attending-text'>{attendingText}</p>
                {rsvpAll === null && (
                  <div className='btn-wrapper'>
                    <Button type='button' onClick={handleRsvpAll} className='initial-btn rsvp-btn-all'>RSVP Whole Party</Button>
                    <p className='attending-text'>{partialAttendingText}</p>
                    <Button type='button' onClick={handleChooseAttendees} className='initial-btn choose-attendees-btn'>Choose Who Will Be Attending</Button>
                  </div>
                )}
                {rsvpAll === false && (
                  <form onSubmit={handleSubmitRsvp} className='select-attending-form'>
                    <p className='select-attending-text'>{selectAttendingText}</p>
                    {guestData.guests.map((guest, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={attendingGuests[guest.guest_id] ?? false}
                            onChange={handleSetAttendingGuests(guest.guest_id)}
                            name={`guest-${index}`}
                            className='guest-checkbox'
                          />
                        }
                        label={`${guest.first_name} ${guest.last_name}`}
                        className='guest-form-control-label'
                      />
                    ))}
                    <Button type='submit' className='rsvp-btn-some'>{rsvpBtnSome}</Button>
                    <Button type='button' onClick={handleReset} className='reset-btn'>Reset</Button>
                  </form>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Fragment>
  );
}

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const globalContext = createContext();

export const GlobalProvider = ({ children }) => {
	const [celebrating, ] = useState({name: 'Chris and Erin', date: 'August 16th 2024' });
  const [global, setGlobal] = useState({
    pages: {
			welcome: { name: 'Welcome', path: '/welcome', settings: {
				welcomeData: {
					welcomeTitle: 'Welcome',
					welcomeMessage: `Please log in using the code from your invitation`,
					label: 'code:',
					submitBtn: 'Submit'
				}
			}, enabled: false },
      home: { name: 'Home', path: '', props: {}, settings: {
				homeData: {
					celebrateMessage: `We're so excited to celebrate with you! Please RSVP by ${celebrating.date}.`,
				}
			}, enabled: true},
      venue: { name: 'Venue', path: '/venue', settings: {
				venueData: {
						start: '7:00pm',
						end: '10:00pm',
						address: '1 Poydras St, New Orleans, LA 70130',
						name: 'The Creole Queen',
						locationText:`We will be cruising down the Mississippi River on the Creole Queen. The boat will begin boarding at 6:00pm and depart at 7:00pm sharp. The boat will return to the dock at 9:00pm`,
						directionsText: 'is docked at 1 Poydras St, New Orleans, LA 70130. The boat is located on the river side of the Hilton Riverside Hotel.',
						activityName: 'Haunted Pub Crawl',
						activityLocationText: 'Join on on a spooky stroll around some of the town\'s most haunted pubs! (21+ requires RSVP)',
						activityDirectionsText: 'The Crawl will meet at 7pm at 723 Saint Peter Street in the French Quarter.'
				}
			}, enabled: true },
      rsvp: { name: 'RSVP', path: '/rsvp', settings: {
				rsvpData: {
					thankYouText: 'Thank you for your RSVP!',
					postRsvpText: 'For cruise and pub crawl details, please see the Venue page.',
					attendingText: 'If all guests on your invitation will be attending the river boat celebration, please choose the option below.',
					selectAttendingText: 'Which guests in your party will be attending?',
					partialAttendingText: 'If only some of the guests on your invitation will be attending please choose the option below.',
					rsvpBtnSome: 'RSVP for attending guests',
					changeRsvpText: 'If you need to make changes to your rsvp, please reach out to Chris or Erin.',
					activityText: 'If you would like to take part in the haunted pub crawl on October 4th at 7pm please check the box below.',
					activityExtraText: 'Please note, The cost of the pub crawl is covered, but individual drinks are not.',
					activityLocation: 'The crawl will meet at 723 Saint Peter Street in the French Quarter.'
				}
			}, enabled: true },
      memories: { name: 'Memories', path: '/memories', settings: {
				memoriesData: {
					memoriesTitle: 'Share your memories',
					socialText: `We would love to see your photos and videos from the wedding. Please use the hashtag #wolfebachwedding when posting to social media.`,
					galleryBtnText: 'View Gallery',
					uploadPhotosText: 'Upload Photos',
					uploadText: `If you would like to upload photos and videos directly to us, please use the form below.`,
					noImageFound: `No has has uploaded any images yet! Want to be the first? See below`
				},
				social: false,
				gallery: true,
				photoUpload: true,
			}, enabled: false },
      yourStay: { name: 'Your Stay', path: '/your-stay', settings: {
				yourStayData: {
					celebratingAccommodations: {
						celebratingTitle: 'Bride and Groom Accommodations',
						stayingText: `Chris and Erin will be staying at the`,
						link: 'https://www.nopsihotel.com/',
						linkText: 'Hotel Website',
						accommodation: 'NOPSI Hotel, New Orleans',
					},
					nearByTitle: 'Nearby Accommodations',
					nearbyOptions: [
						{ title: 'Hilton Riverside Hotel', description: 'Located on the banks of the Mississippi River.', link: 'https://www.guestreservations.com/hilton-new-orleans-riverside/booking', linkText: 'Hotel Website' },
						{ title: 'The Roosevelt New Orleans', description: 'A Waldorf Astoria Hotel.', link: 'https://www.hilton.com/en/hotels/msyrhwa-the-roosevelt-new-orleans', linkText: 'Hotel Website' },
						{ title: 'Old 77 Hotel', description: 'three blocks from the French Quarter', link: 'https://www.old77hotel.com/', linkText: 'Hotel Website' },
						{ title: 'Hotel Monteleone', description: 'The grand dame of Royal Street', link: 'https://hotelmonteleone.com/', linkText: 'Hotel Website' },
						{ title: 'New Orleans Marriott', description: 'A Canal Street location nestled in the French Quarter', link: 'https://www.marriott.com/en-us/hotels/msyla-new-orleans-marriott/overview/', linkText: 'Hotel Website' },
					]
				}
			}, enabled: true },
      registry: {
        name: 'Registry',
        path: '/registry',
        settings: {
          registry: false,
          monetary: true,
          giftCards: false,
					general: false,
          donations: true,
					registryData: {
						Stores: [
							{ Store: 'Williams Sonoma', Link: 'www.google.com' },
						],
						registryWelcome: 'Welcome!',
						registryMessage: `Thank you for considering a gift to help celebrate this occasion. Below you can find options which ${celebrating.name} are open to.`,
						registrySubTitle1: 'Chris and Erin are registered at the following places',
						registeredAt: 'Chris and Erin are registered at the following stores:',
						monetaryText1: 'Have a gift card in mind? Please use the link below.',
						monetaryBtn1: 'Gift Card Link',
						monetaryText2: 'Would you like to give a monetary gift? Please use the link below.',
						monetaryBtn2: 'Monetary Gift Link',
						monetaryText3: 'Would you Like to donate to our honeymoon? Please use the link below.',
						monetaryBtn3: 'Japan 2025',
					},
        }, enabled: true
      },
		},
  });

  return (
    <globalContext.Provider value={{ global, setGlobal, celebrating }}>
      {children}
    </globalContext.Provider>
  );
};

GlobalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

import React, { useState } from 'react';
import { Button, Input, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import pica from 'pica';
import heic2any from 'heic2any';
import './photoUpload.scss';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB limit
const MAX_WIDTH = 800; // Maximum width for resizing
const MAX_HEIGHT = 800; // Maximum height for resizing

export default function PhotoUpload({ onFileSelected, selectedFile }) {
  const [isConverting, setIsConverting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  const resizeImage = async (file) => {
    const img = new Image();
    const reader = new FileReader();
    const picaInstance = pica();

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        img.src = event.target.result;

        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;

          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            if (width > height) {
              height = Math.round((height *= MAX_WIDTH / width));
              width = MAX_WIDTH;
            } else {
              width = Math.round((width *= MAX_HEIGHT / height));
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          try {
            const resizedCanvas = await picaInstance.resize(canvas, canvas);
            resizedCanvas.toBlob((blob) => {
              const resizedFile = new File([blob], 'resized-image.jpeg', {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            }, 'image/jpeg', 0.8); // Adjust quality if needed
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = () => {
          reject(new Error('Error loading image'));
        };
      };

      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds the 5MB limit.');
        return;
      }
      setError('');
      setIsConverting(true);

      try {
        let processedFile = file;
        if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
          processedFile = await convertHEICToJPEG(file);
        }
        const resizedFile = await resizeImage(processedFile);
        onFileSelected(resizedFile);
      } catch (error) {
        console.error('Error processing file:', error);
        setError('Error processing file');
      } finally {
        setIsConverting(false);
      }
    }
  };

  const convertHEICToJPEG = (file) => {
    return heic2any({
      blob: file,
      toType: 'image/jpeg',
    })
      .then((jpegBlob) => {
        return new File([jpegBlob], 'converted-image.jpeg', {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });
      })
      .catch((error) => {
        alert('Error converting HEIC to JPEG');
        console.error('Error:', error);
        throw error;
      });
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append('photo', selectedFile);

    try {
      const response = await fetch('http://localhost:3000/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        await response.json();
        // Handle successful upload (e.g., update state, show message)
      } else {
        console.error('Upload failed');
        // Handle errors
      }
    } catch (error) {
      console.error('Error during upload:', error);
      // Handle errors
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className='photo-upload-container'>
      <form onSubmit={handleUpload} className='photo-upload-form'>
        <Input
          type='file'
          onChange={handleFileChange}
          className='photo-upload-input'
          accept='image/jpeg,image/png,image/heic'
        />
        {error && <div className="error-message">{error}</div>}
        <Button
          type='submit'
          disabled={!selectedFile || isConverting || isUploading}
          variant='contained'
          className={!selectedFile || isConverting || isUploading ? 'photo-upload-btn-disabled' : 'photo-upload-btn-enabled'}
        >
          {(isConverting || isUploading) ? <CircularProgress size={24} /> : 'Upload Photo'}
        </Button>
      </form>
    </div>
  );
}

PhotoUpload.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
};

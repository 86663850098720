import React, { useContext, useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import './nav.scss'
import { globalContext } from '../../config/globalConfig';

const enabledLinksArray = (enabledPages) => {
	const enabledLinks = [];
	for (const page in enabledPages) {
		const pageName = enabledPages[page].name;
		const pageRoute = enabledPages[page].path;
		const pageIcon = enabledPages[page].icon;
		enabledLinks.push({id: page, route: pageRoute, text: pageName, icon: pageIcon});
	}
	return enabledLinks;
};


const logout = () => {
  localStorage.removeItem('isAuthenticated');
  window.location.reload();
};

export default function Navigation() {
	const { global } = useContext(globalContext);
	const { pages } = global;
	const [state, setState] = useState({
    top: false,
  });
	const [enabledPages, setEnabledPages] = useState([]);

	useEffect(() => {
		const enabledPages = [];
		for (const page in pages) {
			if (pages[page].enabled) {
				enabledPages.push(pages[page]);
			}
		}
		setEnabledPages(enabledPages);
	}, [pages]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

	const list = (anchor) => (
		<Box
		sx={{ width: 200}}
		role="presentation"
		onClick={toggleDrawer(anchor, false)}
		onKeyDown={toggleDrawer(anchor, false)}
		className="menu-list-container">
			<List className='list-wrapper'>
				{enabledLinksArray(enabledPages).map((link) => {
					return link.text ? 
					(<ListItem key={link.id} component={Link} to={link.route} className='list-item'>
					<ListItemIcon className='list-icon'>
						{link.icon}
					</ListItemIcon>
					<ListItemText primary={link.text} disableTypography className='list-text'/>
				</ListItem>) : undefined
})}
			</List>
			<Divider />
			<List className='secondary-list-wrapper'>
				<ListItem key="logout" onClick={logout} className='secondary-list-item'>
					<ListItemIcon className='secondary-list-icon'>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText primary="logout" disableTypography className='secondary-list-text'/>
				</ListItem>
			</List>
		</Box>
	);

  return (
    <div className='nav-container'>
			{['left'].map((anchor) => (
				<AppBar position="fixed" key={anchor} className='nav-bar'>
					<Toolbar>
						<Button onClick={toggleDrawer(anchor, true)} className='nav-menu-btn'><MenuIcon /></Button>
						<Drawer
							anchor={anchor}
							open={state[anchor]}
							onClose={toggleDrawer(anchor, false)}
						>
							{list(anchor)}
						</Drawer>
					</Toolbar>
				</AppBar>
			))}
	</div>
  );
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext.jsx';
import './welcome.scss';

export default function Welcome() {
    const [loginData, setLoginData] = useState({
        firstName: '',
        lastName: '',
        code: ''
    });

		const { authenticate } = useAuth();
		const navigate = useNavigate();

    const handleChange = (event) => {
        setLoginData({
            ...loginData,
            [event.target.name]: event.target.value
        });
    };

    const onSubmit = async (event) => {
			event.preventDefault();
			try {
					const url = `/api/data/auth?code=${loginData.code}`;
					const response = await fetch(url);
					const data = await response.json();

					if (data.auth === true) {
						authenticate('true', data);
							navigate('/');
					} else {
							authenticate('false');
					}
			} catch (error) {
					console.error('Error during authentication:', error);
			}
	};

    return (
        <div className='welcome-wrapper'>
            <h1 className='welcome-h1'>Welcome</h1>
            <p className='welcome-login-msg'>Please log in using the code from your invitation</p>
            <form className='welcome-form' onSubmit={onSubmit}>
                <label className='welcome-form-label'>
                    code:
                    <textarea 
                        name='code' 
												className='welcome-form-input'
                        value={loginData.code} 
                        onChange={handleChange}
                    />
                </label>
                <button type='submit' className='welcome-form-btn'>Login</button>
            </form>
        </div>
    );
}

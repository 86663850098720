import React, { Fragment, useContext, useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import useWindowDimension from '../../utils/hooks/useWindowDimensions.jsx';
import { globalContext } from '../../config/globalConfig';
import ResponsiveSideImg from '../../components/responsiveSideImg/index.jsx';
import ResponsiveBanner from '../../components/responsiveBanner/index.jsx';
import image1 from '../../assets/registry/image1.webp';
import image2 from '../../assets/registry/image2.webp';
import image3 from '../../assets/registry/image3.webp';
import './Registry.scss';

export default function Registry() {
  const { global } = useContext(globalContext);
  const {
    registry: {
      settings,
      settings: {
        registryData: {
          Stores,
          registryMessage,
          registryWelcome,
          registrySubTitle1,
          monetaryText1,
          monetaryBtn1,
          monetaryText2,
          monetaryBtn2,
          monetaryText3,
          monetaryBtn3,
        },
      },
    },
  } = global.pages;

  const [open, setOpen] = useState(false);
  const { width } = useWindowDimension();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const MobileView = () => (
    <div className='registry-container'>
      <ResponsiveBanner imagePath={image1} cropPosition='50% 50%' altText="new orleans registry banner" />
      <div className="registry-content">
        <h1 className='registry-welcome'>{registryWelcome}</h1>
        <h2 className='registry-message'>{registryMessage}</h2>
      </div>
      {settings.registry && (
        <div className='registry-content'>
          <h2 className='registry-h2'>{registrySubTitle1}</h2>
          {Stores.map(store => (
            <Button
              key={store.name}
              onClick={() => (window.location.href = store.link)}
              className='monetary-link'
            >
              {store.Store}
            </Button>
          ))}
        </div>
      )}
      {settings.monetary && (
        <div className='registry-content'>
          {settings.giftCards && (
            <div className='registry-content'>
              <h2 className='monetary-h2'>{monetaryText1}</h2>
              <Button
                onClick={() => (window.location.href = 'http://www.google.com')}
                className='monetary-link'
              >
                {monetaryBtn1}
              </Button>
            </div>
          )}
          {settings.general && (
            <div className='registry-content'>
              <h2 className='monetary-h2'>{monetaryText2}</h2>
              <Button
                onClick={() => (window.location.href = 'http://www.google.com')}
                className='monetary-link'
              >
                {monetaryBtn2}
              </Button>
            </div>
          )}
          {settings.donations && (
            <div className='registry-content'>
              <h2 className='monetary-h2'>{monetaryText3}</h2>
              <Button
                onClick={() => handleOpenDialog('Donations', image3)}
                className='monetary-link'
              >
                {monetaryBtn3}
              </Button>
            </div>
          )}
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { overflow: 'hidden' },
        }}
      >
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            height: '80vh', // Set a maximum height for the dialog
          }}
        >
          <img
            src={image3}
            alt="Dialog Content"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const WebView = () => (
    <div className="registry-container">
      <div className="registry-section">
        <ResponsiveSideImg imagePath={image1} altText="Registry Banner" position="right" width="45%" height="80%" />
        <div className="registry-content">
          <h1 className="registry-welcome">{registryWelcome}</h1>
          <h2 className="registry-message">{registryMessage}</h2>
        </div>
      </div>

      {settings.registry && (
        <div className="registry-section">
          <ResponsiveSideImg imagePath={image1} altText="Stores Image" position="left" width="45%" height="80%" />
          <div className="registry-content">
            <h2 className="registry-h2">{registrySubTitle1}</h2>
            {Stores.map(store => (
              <Button
                key={store.name}
                onClick={() => (window.location.href = store.Link)}
                className="monetary-link"
              >
                {store.Store}
              </Button>
            ))}
          </div>
        </div>
      )}

      {settings.monetary && (
        <>
          {settings.giftCards && (
            <div className="registry-section">
              <ResponsiveSideImg imagePath={image1} altText="Gift Card QR Code" position="right" width="45%" height="80%" />
              <div className="registry-content">
                <h2 className="monetary-h2">{monetaryText1}</h2>
                <Button
                  onClick={() => (window.location.href = 'http://www.google.com')}
                  className="monetary-link"
                >
                  {monetaryBtn1}
                </Button>
              </div>
            </div>
          )}

          {settings.general && (
            <div className="registry-section">
              <ResponsiveSideImg imagePath={image1} altText="Monetary Gift QR Code" position="left" width="45%" height="80%" />
              <div className="registry-content">
                <h2 className="monetary-h2">{monetaryText2}</h2>
                <Button
                  onClick={() => (window.location.href = 'http://www.google.com')}
                  className="monetary-link"
                >
                  {monetaryBtn2}
                </Button>
              </div>
            </div>
          )}

          {settings.donations && (
            <div className="registry-section">
              <ResponsiveSideImg imagePath={image2} altText="Donation QR Code" position="left" width="45%" height="80%" />
              <div className="registry-content">
                <h2 className="monetary-h2">{monetaryText3}</h2>
                <Button
                  onClick={() => handleOpenDialog()}
                  className="monetary-link"
                >
                  {monetaryBtn3}
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { overflow: 'hidden' },
        }}
      >
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            height: '80vh', // Set a maximum height for the dialog
          }}
        >
          <img
            src={image3}
            alt="Dialog Content"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <Fragment>
      {width <= 768 ? <MobileView /> : <WebView />}
    </Fragment>
  );
}

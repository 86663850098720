import React, { useContext } from 'react';
import { globalContext } from '../../config/globalConfig';
import nochurch from '../../assets/yourStay/nochurch.webp';
import './yourStay.scss';
import ResponsiveBanner from '../../components/responsiveBanner/index.jsx';

export default function YourStay() {
  const { global } = useContext(globalContext);
  const {
    yourStay: {
      settings: {
        yourStayData: { celebratingAccommodations, nearByTitle, nearbyOptions },
      },
    },
  } = global.pages;
  
  return (
    <div className='your-stay-container'>
			<ResponsiveBanner imagePath={nochurch} cropPosition='50% 60%' altText='a church in new orleans' />
      <h2 className='your-stay-h2'>{celebratingAccommodations.celebratingTitle}</h2>
      <p className='your-stay-text'>
        {celebratingAccommodations.stayingText} {celebratingAccommodations.accommodation}
      </p>
      <a
        href={
          celebratingAccommodations.link.startsWith('http') ? celebratingAccommodations.link : `http://${celebratingAccommodations.link}`
        }
				target='_blank'
				rel="noreferrer"
        className='your-stay-link'
      >
        {celebratingAccommodations.linkText}
      </a>
      <h2 className='nearby-title'>{nearByTitle}</h2>
      {nearbyOptions.map((option, index) => (
        <div key={index} className='nearby-option'>
          <h3 className='nearby-option-title'>{option.title}</h3>
          <p className='nearby-option-description'>{option.description}</p>
          {option.link && (
            <a
              href={
                option.link.startsWith('http') ? option.link : `http://${option.link}`
              }
							target='_blank'
							rel="noreferrer"
              className='nearby-option-link'
            >
              {option.linkText}
            </a>
          )}
        </div>
      ))}
    </div>
  );
}

import React, { useEffect, useState, useContext } from "react";
import Carousel from "react-multi-carousel";
import { globalContext } from "../../config/globalConfig.js";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "react-multi-carousel/lib/styles.css";
import "./photoGallery.scss";

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { global } = useContext(globalContext);
  const {
    memories: {
      settings: { gallery, memoriesData: { noImageFound } },
    },
  } = global.pages;

  useEffect(() => {
    fetch("/images")
      .then((response) => response.json())
      .then((data) => {
        setImages(data.urls);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        setLoading(false);
      });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="photo-gallery-container">
			{!images.length && !loading && <p>{noImageFound}</p>}
      {gallery && images && !loading ? (
        <Carousel
          responsive={responsive}
          containerClass="carousel-container"
          itemClass="carousel-item"
          infinite={true}
        >
          {images.map((url, index) => (
            <div className="carousel-item-wrapper" key={index}>
              <img
                src={url}
                alt={`Wedding ${index}`}
                className="gallery-img"
                loading="lazy" // Lazy loading attribute
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default ImageGallery;

ImageGallery.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import './sideImg.scss';

const ResponsiveSideImg = ({ imagePath, altText, position, width, height }) => {
  const commonStyle = {
    height: '100%', // Span the full height of the container
    width: '100%', // Span the full width of the container
    objectFit: 'cover',
  };

  const containerStyle = {
    width: width, // Use the width prop
    height: height, // Use the height prop
    flexShrink: 0,
  };

  return (
    <div className={`banner-container ${position}`} style={containerStyle}>
      <img src={imagePath} alt={altText} className="banner-image" style={commonStyle} />
    </div>
  );
};

ResponsiveSideImg.propTypes = {
  imagePath: PropTypes.string.isRequired,
  altText: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired, // Add height as a required prop
};

ResponsiveSideImg.defaultProps = {
  altText: 'Banner Image',
  position: 'right', // Default to right if no position is provided
  width: '30%', // Default width, adjust as needed
  height: '100%', // Default height, adjust as needed
};

export default ResponsiveSideImg;

import React, { useContext } from 'react';
import { globalContext } from '../../config/globalConfig';
import ResponsiveSideImg from '../../components/responsiveSideImg/index.jsx';
import aiboat from '../../assets/locationDetails/aiboat.webp';
import './locationDetails.scss';

export default function LocationDetails() {
  const { global } = useContext(globalContext);
  const {
    venue: {
      settings: {
        venueData: { name, locationText, directionsText, activityName, activityLocationText, activityDirectionsText },
      },
    },
  } = global.pages;

  return (
    <div className="location-details">
      <ResponsiveSideImg imagePath={aiboat} altText="A river boat going down the Mississippi" position="left" width="45%" height="80%" />
      <div className="location-content">
        <h1 className="location-h1">{name}</h1>
        <p className="location-text">{locationText}</p>
        <h2 className="location-h2">Directions</h2>
        <p className="location-text">{`${name} ${directionsText}`}</p>
				<div className="location-content">
        <h1 className="location-h1">{activityName}</h1>
        <p className="location-text">{activityLocationText}</p>
        <h2 className="location-h2">Directions</h2>
        <p className="location-text">{`${activityName} ${activityDirectionsText}`}</p>
      </div>
      </div>
    </div>
  );
}

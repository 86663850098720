import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider, useAuth } from './utils/AuthContext.jsx';
import { RsvpProvider } from './utils/rsvpContext.jsx';
import { GlobalProvider } from './config/globalConfig';
import { globalContext } from './config/globalConfig';
import PropTypes from 'prop-types';
import Main from './pages/main/index.jsx';
import Home from './pages/home/index.jsx';
import Rsvp from './pages/rsvp/index.jsx';
import LocationDetails from './pages/locationDetails/index.jsx';
import Memories from './pages/memories/index.jsx';
import YourStay from './pages/yourStay/index.jsx';
import Registry from './pages/Registry/index.jsx';
import NoPage from './pages/noPage/index.jsx';
import Welcome from './pages/welcome/index.jsx';

const AuthWrapper = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Welcome />;
};

const PageEnabledWrapper = ({ children, objKey }) => {
  const { global } = React.useContext(globalContext);
  const { pages } = global;
  return pages[objKey]['enabled'] ? children : <NoPage />;
};

const router = createBrowserRouter([
  {
    path: '*',
    element: <NoPage />,
  },
  {
    path: '/',
    element: (
      <AuthWrapper>
        <GlobalProvider>
          <Main />
        </GlobalProvider>
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <AuthWrapper>
            <PageEnabledWrapper objKey='home'>
              <Home />
            </PageEnabledWrapper>
          </AuthWrapper>
        ),
      },
      {
        path: 'venue',
        element: (
          <AuthWrapper>
            <PageEnabledWrapper objKey='venue'>
              <LocationDetails />
            </PageEnabledWrapper>
          </AuthWrapper>
        ),
      },
      {
        path: 'rsvp',
        element: (
          <AuthWrapper>
            <RsvpProvider>
              <PageEnabledWrapper objKey='rsvp'>
                <Rsvp />
              </PageEnabledWrapper>
            </RsvpProvider>
          </AuthWrapper>
        ),
      },
      {
        path: 'memories',
        element: (
          <AuthWrapper>
            <PageEnabledWrapper objKey='memories'>
              <Memories />
            </PageEnabledWrapper>
          </AuthWrapper>
        ),
      },
      {
        path: 'your-stay',
        element: (
          <AuthWrapper>
            <PageEnabledWrapper objKey='yourStay'>
              <YourStay />
            </PageEnabledWrapper>
          </AuthWrapper>
        ),
      },
      {
        path: 'Registry',
        element: (
          <AuthWrapper>
            <PageEnabledWrapper objKey='registry'>
              <Registry />
            </PageEnabledWrapper>
          </AuthWrapper>
        ),
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

PageEnabledWrapper.propTypes = {
  children: PropTypes.node,
  objKey: PropTypes.string.isRequired,
};

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [guestData, setGuestData] = useState(null);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authStatus);
    if (authStatus) {
      // Assuming guest data is stored as a JSON string in localStorage
      const storedGuestData = localStorage.getItem('guestData');
      if (storedGuestData) {
        setGuestData(JSON.parse(storedGuestData));
      }
    }
  }, []);

  const authenticate = (value, data) => {
    localStorage.setItem('isAuthenticated', value);
    setIsAuthenticated(value);
    if (value && data) {
      localStorage.setItem('guestData', JSON.stringify(data)); // Store guest data in localStorage
      setGuestData(data); // Update state
    } else {
      localStorage.removeItem('guestData'); // Clear guest data if logging out
      setGuestData(null);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, authenticate, guestData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const RsvpContext = createContext();

export const RsvpProvider = ({ children }) => {
  const [rsvpData, setRsvpData] = useState(null);

  useEffect(() => {
    const fetchRsvpData = async () => {
      try {
        const response = await fetch('/api/data/getRsvp', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const guestRsvpList = await response.json();
        setRsvpData(guestRsvpList);
      } catch (error) {
        console.error('Error fetching RSVP data:', error);
      }
    };

    fetchRsvpData();
  }, []);

  return (
    <RsvpContext.Provider value={{ rsvpData }}>
      {children}
    </RsvpContext.Provider>
  );
};

export const useRsvp = () => useContext(RsvpContext);

RsvpProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

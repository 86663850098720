import React from 'react';
import PropTypes from 'prop-types';
import './banner.scss';

const ResponsiveBanner = ({ imagePath, altText, cropPosition }) => {
  return (
    <div className="banner-container">
      <img src={imagePath} alt={altText} className="banner-image" style={{ objectPosition: cropPosition }} />
    </div>
  );
};

ResponsiveBanner.propTypes = {
  imagePath: PropTypes.string.isRequired,
  altText: PropTypes.string,
  cropPosition: PropTypes.string,
};

ResponsiveBanner.defaultProps = {
  altText: 'Banner Image',
  cropPosition: 'center', // Default to center if no crop position is provided
};

export default ResponsiveBanner;
